import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Trans } from 'react-i18next';
import Image from 'gatsby-image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';

import ManumentLevaSlider from '../manument-leva-slider';
import ManumentLevaSlider2 from '../manument-leva-slider-2';
import ContentColumn from '../../components/content-column/content-column';
import illustrationImage from '../../images/Leva_Seitansicht_Kaffee.svg';

const query = graphql`
  {
    image01: file(
      relativePath: { eq: "manument-leva/technik/Manument_Leva.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    image02: file(
      relativePath: { eq: "manument-leva/technik/Manument_FINAL-326.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

const ManumentLevaContent = () => {
  const data = useStaticQuery(query);

  return (
    <Container>
      <Row>
        <Col md={6} className="p-0">
          <ManumentLevaSlider />
        </Col>
        <ContentColumn md={6}>
          <Trans i18nKey="text-01" ns="manument-leva-maschine">
            <h2>Leva-Maschine mit Ansprüchen</h2>
            <p>
              Diese Maschine erwartet genauso viel von Ihnen, wie Sie von ihr
              erwarten können: Hingabe, Experimentierfreude und Anspruch an
              wirklich guten Espresso. Zusätzlich erhalten Sie ein im
              schweizerischen Thun handgefertigtes Designobjekt, ein Stück
              Ingenieurskunst, das gemeinsam mit Baristameistern entwickelt
              wurde und eine Maschine, die die maximal mögliche Kontrolle über
              die Extraktion zurück in Ihre Hände legt. Eben da, wo sie
              hingehört.
            </p>
          </Trans>
        </ContentColumn>
      </Row>
      <Row>
        <ContentColumn lg={{ offset: 2, span: 8 }} spacingY>
          <Trans i18nKey="technical-data" ns="manument-leva-maschine">
            <h2>Technische Daten</h2>
            <Table borderless responsive>
              <tbody>
                <tr>
                  <td>Maschinenart</td>
                  <td>Handhebelmaschine mit Feder und Kolben</td>
                </tr>
                <tr>
                  <td>Breite</td>
                  <td>ca. 204 mm / 8 in</td>
                </tr>
                <tr>
                  <td>Tiefe (inkl. Siebträger)</td>
                  <td>ca. 655 mm / 26 in</td>
                </tr>
                <tr>
                  <td>Höhe mit Leva</td>
                  <td>ca. 760 mm / 30 in</td>
                </tr>
                <tr>
                  <td>Höhe ohne Leva</td>
                  <td>ca. 620 mm / 24 in</td>
                </tr>
                <tr>
                  <td>Gewicht</td>
                  <td>ca. 44 kg / 97 pounds</td>
                </tr>
                <tr>
                  <td>Netzspannung - Stromstärke</td>
                  <td>230 V-AC – 16 Ampere</td>
                </tr>
                <tr>
                  <td>Leistung</td>
                  <td>max. 3400 Watt</td>
                </tr>
                <tr>
                  <td>Wassertankvolumen</td>
                  <td>max. 1,6 l / 54 oz</td>
                </tr>
                <tr>
                  <td>Features</td>
                  <td>
                    54mm Siebträger <br />
                    Multi-Durchlauferhitzer (individuelle Heizungen für Kaffee
                    und Dampf/Heißwasser), manuelles Wasser-Flow-System,
                    Dampf-/Heißwasserlanze
                  </td>
                </tr>
              </tbody>
            </Table>
          </Trans>
        </ContentColumn>
      </Row>
      <Row style={{ marginBottom: '4rem', marginTop: '-2rem' }}>
        <ContentColumn md={6}>
          <Trans i18nKey="text-02" ns="manument-leva-maschine">
            <h2>
              Nichts, wie es war.
              <br />
              Aber alles, wie es sein soll.
            </h2>
            <p>
              Die MANUMENT Leva-Maschine ist das Ergebnis einer im Wortsinn
              radikalen Idee: Die klassische Handhebelmaschine von ihren Wurzeln
              her neu denken, Stärken herausstellen und Schwächen ausräumen.
              Nötig dazu war eine 18 Monate andauernde Entwicklungsarbeit, in
              der jede Mechanik, jede Technik und jede Schraube überdacht wurde.
              Und zwar in einem kleinen Team aus Schweizer Ingenieuren,
              Industriedesignern und Baristameistern. Unser Ziel? Ein
              innovatives Stück Technik, das Espressokunst wieder be-greifbar
              macht.
            </p>
          </Trans>
        </ContentColumn>
        <Col
          md={6}
          lg={{ offset: 1, span: 4 }}
          className="d-flex flex-column justify-content-center"
        >
          <Image {...data.image01.childImageSharp} />
        </Col>
      </Row>
      <Row>
        <ContentColumn centered gray spacingY>
          <Trans i18nKey="cta-01" ns="manument-leva-maschine">
            <ContentColumn.LargeH2>
              Mehr Kontrolle bedeutet:
              <br />
              mehr Freiheit.
            </ContentColumn.LargeH2>
          </Trans>
        </ContentColumn>
      </Row>
      <Row>
        <ContentColumn md={{ offset: 1, span: 10 }} spacingY className={'pb-0'}>
          <div
            style={{
              width: '100%',
              paddingBottom: '56.25%',
              position: 'relative',
            }}
          >
            <iframe
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
              }}
              src="https://player.vimeo.com/video/538619357"
              frameBorder="0"
              allowFullScreen
            />
          </div>
        </ContentColumn>
      </Row>
      <Row>
        <ContentColumn md={{ offset: 1, span: 10 }} spacingY>
          <Trans i18nKey="text-03" ns="manument-leva-maschine">
            <h2>Wasser-Fluss System</h2>
            <p>
              Die MANUMENT Leva-Maschine besitzt ein manuelles Wasser-Fluss
              System, das die Möglichkeit bietet, die gewünschte Intensität des
              Wasserzuflusses auf den Kaffeekuchen zu bestimmen. Das bedeutet:
              In fünf Öffnungsstufen kann der Massestrom (Gramm Wasser pro
              Sekunde) an die Wünsche des Barista und das Aroma der jeweiligen
              Röstung angepasst werden. Das Ergebnis ist ein gänzlich
              individuelles und harmonisches Geschmackserlebnis – sowohl für
              Anfänger als auch für Profi-Baristi.
            </p>
          </Trans>
        </ContentColumn>
      </Row>
      <Row>
        <Col md={6} style={{ padding: 0 }}>
          <ManumentLevaSlider2 />
        </Col>
        <ContentColumn md={6}>
          <Trans i18nKey="text-04" ns="manument-leva-maschine">
            <h2>Multi-Durchlauferhitzersystem</h2>
            <p>
              Das von uns eigens neu entwickelte Multi-Durchlauferhitzersystem
              (zum Patent angemeldet) erhitzt ausschließlich die nötige Menge
              Wasser für einen doppelten Espressoshot oder das Milchaufschäumen.
              Somit produziert die Manument Leva-Maschine geringste Abwärme, ist
              sofort einsatzbereit und besonders energieeffizient. Auch die
              Überhitzung und Temperaturschwankung bei längerer Benutzung, die
              bei klassischen Handhebelmaschinen auftreten, wird auf diese Weise
              vermieden. Das Wasser wird auf die vom Barista eingestellte
              Brühtemperatur eingestellt und während des Erhitzens konstant
              gehalten.
            </p>
          </Trans>
        </ContentColumn>
      </Row>
      <Row>
        <ContentColumn
          md={6}
          colClassName="p-0"
          className="justify-content-start"
          spacingY
        >
          <Trans i18nKey="text-05" ns="manument-leva-maschine">
            <h2>Die Espressozubereitung mit einer MANUMENT Leva-Maschine</h2>
            <p>
              Unsere Leva-Maschine bietet ein sinnliches und spielerisches
              Erlebnis der Espressozubereitung. Mit den folgenden Schritten kann
              dieses Erlebnis individualisiert und auf den persönlichen
              Geschmack bzw. das Aroma der Röstung abgestimmt werden:
            </p>
          </Trans>
          <img
            src={illustrationImage}
            alt="Manument Leva Maschine Illustration"
            style={{ marginTop: '4.5rem' }}
          />
          <Trans i18nKey="text-06" ns="manument-leva-maschine">
            <p className="mt-5">
              Für das beste Benutzererlebnis wird zu Beginn ein Standardprofil
              empfohlen. Wir empfehlen dem Barista, in Folge mit verschiedenen
              Öffnungssequenzen zu experimentieren, um den optimalen Geschmack
              aus den gewählten Bohnen zu extrahieren.
            </p>
          </Trans>
        </ContentColumn>
        <ContentColumn md={6} gray spacingY>
          <Trans i18nKey="list-01" ns="manument-leva-maschine">
            <ol>
              <li>
                Durch das Spannen des Leva-Hebels werden im Inneren des
                Zylinders zwei Federn gespannt. Hierbei bewegt sich der Kolben
                nach oben und es wird gleichzeitig Wasser aus dem Tank
                angesaugt.
              </li>
              <li>
                Sobald der Leva-Hebel beim Anschlag losgelassen wird, wirkt ein
                Druck von ca. 9 bar zwischen Kolben und Wasser-Flow-Ventil.
              </li>
              <li>
                Nach dem Öffnen des Wasser-Flow-Ventils strömt das Wasser durch
                die Heizung und durch die Dusche in den Siebträger, wo zuerst
                der Leerraum oberhalb des gemahlenen Kaffees geflutet wird.
              </li>
              <li>
                Wenn der Kaffee mit heißem Wasser gesättigt ist, entsteht im
                Siebträger der für die Extraktion notwendige Gegendruck.
              </li>
              <li>
                Während der Extraktion bewegt sich der Kolben wieder nach unten
                und der Leva-Hebel kehrt zurück in die Ausgangsposition. Die
                Strömung kann während der ganzen Extraktion mit dem
                Wasser-Flow-Ventil gesteuert werden und ist durch die Bewegung
                des Leva-Hebels sichtbar.
              </li>
            </ol>
          </Trans>
        </ContentColumn>
      </Row>
    </Container>
  );
};

export default ManumentLevaContent;
