import React from 'react';
import Image from 'gatsby-image';
import TinySlider from 'tiny-slider-react';

import './slider.module.scss';

const settings = {
  controls: true,
  controlsText: ['', ''],
  nav: false,
};

const Slider = ({ images }) => (
  <TinySlider settings={settings}>
    {images.map((imageProps, i) => (
      <div key={i}>
        <Image {...imageProps} />
      </div>
    ))}
  </TinySlider>
);

export default Slider;
