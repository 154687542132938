import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './switchable-slider.module.scss';

import Slider from '../slider/slider';

const SwitchableSlider = ({ initialGroup, data = [] }) => {
  const [group, setGroup] = useState(initialGroup);

  return (
    <div className={styles.root}>
      <Slider images={data.find(({ name }) => name === group).images} />
      <div className={styles.buttons}>
        {data.map(({ name }) => (
          <button
            className={classnames(styles.button, styles[name])}
            key={name}
            onClick={() => setGroup(name)}
          >
            {name}
          </button>
        ))}
      </div>
    </div>
  );
};

SwitchableSlider.propTypes = {
  initialGroup: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          fluid: PropTypes.any,
        })
      ),
    })
  ).isRequired,
};

export default SwitchableSlider;
