import React from 'react';

import SEO from '../components/seo';
import { HeroState } from '../components/hero/hero-context';
import ManumentLevaContent from '../content/de/manument-leva-content';
import PageWrapper from '../components/page-wrapper/page-wrapper';
import NewsletterBox from '../components/newsletter-box/newsletter-box';
import { useTranslation } from 'react-i18next';

const FrancescaPage = () => {
  const { t } = useTranslation('manument-leva-maschine');
  return (
    <PageWrapper padding>
      <SEO title="Manument Leva Maschine" />
      <HeroState title={t('header.title')} imageName="machine--side-black" />
      <ManumentLevaContent />
      <NewsletterBox />
    </PageWrapper>
  );
};

export default FrancescaPage;
