import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SwitchableSlider from '../components/switchable-slider/switchable-slider';

const query = graphql`
  {
    allFile(
      filter: { relativePath: { glob: "manument-leva/slider/**" } }
      sort: { fields: name }
    ) {
      group(field: relativeDirectory) {
        fieldValue
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 799) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`;

const ManumentLevaSlider = () => {
  const data = useStaticQuery(query);
  const sliderData = data.allFile.group.map((group) => ({
    // Get the last part of the directory name.
    name: group.fieldValue.split('/').pop(),
    images: group.edges.map((edge) => edge.node.childImageSharp),
  }));

  return <SwitchableSlider initialGroup="white" data={sliderData} />;
};

export default ManumentLevaSlider;
